import {
  Container,
  Col,
  Button,
  Image,
  ProgressBar,
  Form,
  Modal,
  Table,
  Row,
} from "react-bootstrap";
import {
  ArrowDownCircle,
  ArrowUpRight,
  Award,
  BarChart,
  Calendar,
  CheckCircle,
  Link,
  LogOut,
  MapPin,
  Share2,
} from "react-feather";
import {
  formatCurrencyInput,
  formatDate,
  formatNumberWithout,
  generateShareUrl,
  maskName,
  removeNonNumbers,
} from "../../helper";
import TicketIcon from "../../assets/ticket.svg";

import PocketsLevelLogo1 from "../../assets/pockets-level-1.svg";
import PocketsLevelLogo2 from "../../assets/pockets-level-2.svg";
import PocketsLevelLogo3 from "../../assets/pockets-level-3.svg";
import PocketsLevelLogo4 from "../../assets/pockets-level-4.svg";
import PocketsLevelLogo5 from "../../assets/pockets-level-5.svg";

//social
import Facebook from "../../assets/social/facebook.svg";
import WhatsApp from "../../assets/social/whatsapp.svg";
import Twitter from "../../assets/social/twitter.svg";
import QRIcon from "../../assets/social/telegram.svg";

import Select from "react-select";
import { useCallback, useEffect, useState } from "react";
import {
  event_list,
  history,
  leaderboard_list,
  profile,
  share,
  withdraw,
} from "./fetch";
import toast from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";

const Home = () => {
  const [showEventShare, setShowEventShare] = useState(false);
  const [modalWithdrawRequest, setModalWithdrawRequest] = useState(false);
  const [dataEvents, setDataEvents] = useState([]);
  const [dataLeaderboard, setDataLeaderboard] = useState([]);
  const [dataEventShare, setDataEventShare] = useState(null);
  const [dataProfile, setDataProfile] = useState(null);
  const [dataHistory, setDataHistory] = useState([]);
  const [search, setSearch] = useState("");

  const [withdrawAmount, setWithdrawAmount] = useState("");

  const getRemainingDaysInMonth = () => {
    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    return lastDayOfMonth.getDate() - today.getDate();
  };

  const affiliateLevel = [
    {
      level: 1,
      icon: PocketsLevelLogo1,
      color: "#089391",
    },
    {
      level: 1,
      icon: PocketsLevelLogo1,
      color: "#089391",
      min_transaction: "1500000",
    },
    {
      level: 2,
      icon: PocketsLevelLogo2,
      color: "#0055b0",
      min_transaction: "2500000",
    },
    {
      level: 3,
      icon: PocketsLevelLogo3,
      color: "#a7489f",
      min_transaction: "2500000",
    },
    {
      level: 4,
      icon: PocketsLevelLogo4,
      color: "#9b3600",
      min_transaction: "4500000",
    },
    {
      level: 5,
      icon: PocketsLevelLogo5,
      color: "#cb800f",
      min_transaction: "5000000",
    },
  ];

  const handleGetEventList = useCallback(async () => {
    try {
      const res = await event_list();
      if (res.status) {
        setDataEvents(res.data);
      } else {
        setDataEvents([]);
      }
    } catch (error) {
      setDataEvents([]);
    }
  }, []);

  const handleGetProfile = useCallback(async () => {
    try {
      const res = await profile();
      if (res.status) {
        setDataProfile(res.data);
      } else {
        setDataProfile([]);
      }
    } catch (error) {
      setDataProfile([]);
    }
  }, []);

  const handleGetHistory = useCallback(async () => {
    try {
      const res = await history();
      if (res.status) {
        setDataHistory(res.data);
      } else {
        setDataHistory([]);
      }
    } catch (error) {
      setDataHistory([]);
    }
  }, []);

  const handleGetLeaderboard = useCallback(async () => {
    try {
      const res = await leaderboard_list();
      if (res.status) {
        setDataLeaderboard(res.data?.top_amount);
      } else {
        setDataLeaderboard([]);
      }
    } catch (error) {
      setDataLeaderboard([]);
    }
  }, []);

  const handleShareEvent = useCallback(
    async (platform) => {
      const alerts = toast.loading(
        "Tunggu Sebentar, Sedang Membuat Kode Untuk Dibagikan"
      );
      try {
        const res = await share(dataEventShare?.event_id);
        if (res.status) {
          toast.dismiss(alerts);
          if (platform === "copy") {
            copyToClipboard(res.data?.link_copy || "");
          } else {
            const url = generateShareUrl(
              platform,
              dataEventShare?.event_name,
              res.data?.code
            );
            if (url) window.location.replace(url);
          }
        } else {
          setDataLeaderboard([]);
        }
      } catch (error) {
        setDataLeaderboard([]);
      }
    },
    [dataEventShare]
  );

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        const successful = document.execCommand("copy");
        document.body.removeChild(textArea);

        if (!successful) throw new Error("Copy failed");
      }
      toast.success("Link berhasil disalin!");
    } catch (err) {
      toast.error("Link gagal disalin, Membuka Link Affiliasi!");
      setTimeout(() => {
        window.open(text, "_blank");
      }, 2000);
    }
  };

  const handleRequestWithdraw = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const params = {
          withdraw_amount: removeNonNumbers(withdrawAmount),
        };
        const res = await withdraw(params);
        if (res.status) {
          setModalWithdrawRequest(false);
          setWithdrawAmount("");
          toast.success(
            "Pengajuan Berhasil, Mohon Menunggu Konfirmasi Dari Whatsapp Untuk Status Pencairan"
          );
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        setModalWithdrawRequest(false);
        setWithdrawAmount("");
      }
    },
    [withdrawAmount]
  );

  const filteredEvents =
    dataEvents?.filter(
      (event) =>
        !search ||
        event?.event_name?.toLowerCase().includes(search.toLowerCase())
    ) || [];

  useEffect(() => {
    handleGetEventList();
    handleGetProfile();
    handleGetLeaderboard();
    handleGetHistory();
  }, [
    handleGetEventList,
    handleGetHistory,
    handleGetLeaderboard,
    handleGetProfile,
  ]);

  return (
    <Container className="home" fluid>
      <Modal
        size="xl"
        show={showEventShare}
        onHide={() => [setShowEventShare(false), setDataEventShare(null)]}
        centered
      >
        <Modal.Header closeButton>Detail Event dan Komisi</Modal.Header>
        <Modal.Body>
          <Image
            src={`${process.env.REACT_APP_API_URL_IMAGE}${dataEventShare?.event_image}`}
            className="w-100 mb-3"
            style={{ borderRadius: "10px" }}
          />
          <Col
            className="d-flex align-items-center mb-3"
            style={{
              backgroundColor: "var(--primary-color)",
              borderRadius: "5px",
              paddingBlock: "5px",
              paddingInline: "20px",
              width: "fit-content",
              color: "white",
            }}
          >
            <Calendar style={{ marginRight: "5px" }} size={15} />
            <h6 style={{ marginBottom: "-2px" }}>
              {formatDate(dataEventShare?.event_enddate || "")} •{" "}
              {(dataEventShare?.event_endtime || "").replace(":", ".")}
            </h6>
          </Col>
          <h5>{dataEventShare?.event_name}</h5>
          <h6 className="fw-lighter text-black">
            <MapPin size={17} /> {dataEventShare?.event_address}
          </h6>
          <Table
            responsive
            className="text-nowrap table-fixed mb-4"
            style={{
              borderRight: "0.6px solid gray",
              borderLeft: "0.6px solid gray",
              borderTop: "0.6px solid gray",
            }}
          >
            <thead className="table-light">
              <tr>
                <th
                  className="text-center"
                  scope="col"
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.6px solid gray",
                  }}
                >
                  Kategori Komisi
                </th>
                <th
                  className="text-center"
                  scope="col"
                  style={{ backgroundColor: "white" }}
                >
                  Komisi
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="text-center"
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.6px solid gray",
                  }}
                >
                  Tertinggi
                </td>
                <td className="text-center">
                  Rp{formatNumberWithout(String(dataEventShare?.max_fee))}
                </td>
              </tr>
              <tr>
                <td
                  className="text-center"
                  style={{
                    backgroundColor: "white",
                    borderRight: "0.6px solid gray",
                  }}
                >
                  Terendah
                </td>
                <td className="text-center">
                  Rp{formatNumberWithout(String(dataEventShare?.min_fee))}
                </td>
              </tr>
            </tbody>
          </Table>
          <h5 className="mb-3">Bagikan ke</h5>
          <Row className="">
            <Col
              className="text-center"
              xs={3}
              onClick={() => handleShareEvent("facebook")}
            >
              <Image src={Facebook} className="mb-3" />
            </Col>
            <Col
              className="text-center"
              xs={3}
              onClick={() => handleShareEvent("whatsapp")}
            >
              <Image src={WhatsApp} className="mb-3" />
            </Col>
            <Col
              className="text-center"
              xs={3}
              onClick={() => handleShareEvent("twitter")}
            >
              <Image src={Twitter} className="mb-3" />
            </Col>
            <Col
              className="text-center"
              xs={3}
              onClick={() => handleShareEvent("telegram")}
            >
              <Image src={QRIcon} className="mb-3" />
            </Col>
          </Row>
          <Button
            className="btn-primary"
            onClick={() => handleShareEvent("copy")}
          >
            <Link size={17} /> Salin Link
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalWithdrawRequest}
        onHide={() => [setModalWithdrawRequest(false), setWithdrawAmount("")]}
        centered
        size="xl"
      >
        <Modal.Header closeButton>Tarik Saldo</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleRequestWithdraw}>
            <Form.Label>Pilih Nominal Penarikan Saldo</Form.Label>
            <Form.Control
              type="text"
              maxLength={10}
              className="mb-3"
              onChange={(e) =>
                setWithdrawAmount(
                  formatCurrencyInput(removeNonNumbers(e.target.value))
                )
              }
              value={`Rp${withdrawAmount}`}
            />
            <Button type="submit" className="btn-primary">
              Tarik Saldo
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="fixed-nav" />
      <Col className="d-flex align-items-center justify-content-between mb-3 px-4">
        <h5 className="text-white fw-bold">
          Halo, {dataProfile?.customer?.fullname || ""}!
        </h5>
        <LogOut
          color="red"
          onClick={() => [
            secureLocalStorage.clear(),
            toast.success("Berhasil Logout"),
            setTimeout(() => {
              window.location.reload();
            }, 3000),
          ]}
        />
      </Col>
      <Col className="box-balance">
        <h5 className="fw-lighter"> Saldo Aktif</h5>
        <h3 className="mb-3">
          Rp
          {formatNumberWithout(String(dataProfile?.customer?.balance || "0"))}
        </h3>
        <Button
          className="btn-primary-balance"
          onClick={() => setModalWithdrawRequest(true)}
        >
          <ArrowDownCircle style={{ marginRight: "5px" }} />
          Tarik Saldo
        </Button>
      </Col>
      <Col
        className={`box-affiliate-level-${
          affiliateLevel?.[
            dataProfile?.affiate_detail?.affiliator_level_setting_id || 1
          ]?.level
        } mb-3`}
      >
        <Col className="d-flex align-items-center justify-content-between">
          <h5 className="fw-bold">
            AFFILIATE LEVEL{" "}
            {
              affiliateLevel?.[
                dataProfile?.affiate_detail?.affiliator_level_setting_id || 1
              ]?.level
            }
          </h5>
          <Image
            src={
              affiliateLevel?.[
                dataProfile?.affiate_detail?.affiliator_level_setting_id || 1
              ]?.icon
            }
          />
        </Col>
        <h6 className="text-white">Total Penghasilan</h6>
        <h3 className="mb-3 text-white">
          Rp
          {formatNumberWithout(
            String(dataProfile?.total_amount_history || "0")
          )}
          /Rp
          {formatNumberWithout(
            affiliateLevel?.[
              dataProfile?.affiate_detail?.affiliator_level_setting_id || 1
            ]?.min_transaction
          )}
        </h3>
        <Col className="d-flex align-items-center mb-3">
          <Col xs={11} style={{ marginRight: "5px" }}>
            <ProgressBar
              now={
                (dataProfile?.total_amount_history /
                  affiliateLevel?.[
                    dataProfile?.affiate_detail?.affiliator_level_setting_id ||
                      1
                  ]?.min_transaction) *
                100
              }
              visuallyHidden={true}
              animated={true}
              style={{ height: "10px", color: "red" }} // Contoh styling
              className="progress progress-stacked"
            />
          </Col>
          <BarChart style={{ marginTop: "-5px" }} />
        </Col>
        <h6>
          Share link lebih banyak lagi dan dapatkan penghasilan Rp 400.000 lagi
          sebelum tanggal 31 Januari untuk upgrade level affiliatormu ke Level
          2!
        </h6>
      </Col>
      <h3 className="text-black fw-bold">Bagikan Event</h3>
      <Form.Control
        placeholder="Cari Event"
        className="mb-2"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Col className="mb-3 d-flex" style={{ overflow: "scroll" }}>
        {filteredEvents?.map((item, index) => {
          return (
            <Col className="py-2 px-1" xs={10} key={index}>
              <Col className="card-event">
                <Image
                  src={`${process.env.REACT_APP_API_URL_IMAGE}${item?.event_image}`}
                  className="w-100 mb-3"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
                <Col className="px-3">
                  <Col className="align-items-center justify-content-center d-flex">
                    <Button
                      className="share-event"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL_EVENT}${item?.event_slug}/${item?.event_id}`
                        )
                      }
                    >
                      Lihat Detail Event{" "}
                      <ArrowUpRight size={20} style={{ marginTop: "-2px" }} />
                    </Button>
                  </Col>
                  <h5 className="fw-bold mb-3">{item?.event_name || ""}</h5>
                  <h6>
                    <Calendar
                      color="var(--primary-color)"
                      size={16}
                      style={{ marginRight: "5px", marginTop: "-4px" }}
                    />
                    {formatDate(item?.event_enddate || "")} •{" "}
                    {(item?.event_endtime || "").replace(":", ".")}
                  </h6>
                  <h6>
                    <MapPin
                      color="var(--primary-color)"
                      size={16}
                      style={{ marginRight: "5px", marginTop: "-4px" }}
                    />
                    {item?.event_address}
                  </h6>
                  <hr />
                  <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col xs={2}>
                      <Image src={TicketIcon} />
                    </Col>
                    <Col
                      xs={10}
                      style={{ marginLeft: "-10px", marginBottom: "-5px" }}
                    >
                      <h6 className="mb-0">Keuntungan Hingga</h6>
                      <h5 className="text-start fw-bold">
                        Rp{formatNumberWithout(String(item?.max_fee) || "0")}
                        /tix
                      </h5>
                    </Col>
                  </Col>
                  <Button
                    className="btn-primary mb-3"
                    onClick={() => [
                      setShowEventShare(true),
                      setDataEventShare(item),
                    ]}
                  >
                    <Share2
                      style={{ marginRight: "10px", marginTop: "-5px" }}
                      size={16}
                    />
                    Bagikan Event
                  </Button>
                </Col>
              </Col>
            </Col>
          );
        })}
      </Col>
      <h3 className="text-black fw-bold">Leaderboard</h3>
      <Select
        className="mb-2"
        options={[
          { value: "by-omzet", label: "Dari Omzet" },
          { value: "by-total", label: "Dari Quantity Tiket" },
        ]}
        isSearchable={false}
      />
      <Col className="py-2 px-1 mb-3" xs={12}>
        <Col className="card-event p-3">
          <h5 className="mb-3">Top Pockets Affiliator</h5>
          <h6
            className="mb-4"
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "4px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Sisa {getRemainingDaysInMonth()} hari lagi, sebelum diperbarui
          </h6>
          {dataLeaderboard?.map((item, index) => {
            return (
              <Col key={index}>
                {index === 0 ? (
                  <Col className="leaderboard-number-one mb-4">
                    <h5 className="text-black mb-3">
                      <Award style={{ marginRight: "5px" }} />
                      Affiliator Terbaik Bulan Ini
                    </h5>
                    <Col className="d-flex align-items-center justify-content-between text-black">
                      <h6>
                        {index + 1}. {maskName(item?.fullname || "")}
                      </h6>
                      <h6>
                        Rp
                        {formatNumberWithout(String(item?.total_amount || ""))}
                      </h6>
                    </Col>
                  </Col>
                ) : (
                  <>
                    <Col className="d-flex align-items-center justify-content-between text-black mb-2">
                      <h6>
                        {index + 1}. {maskName(item?.fullname || "")}
                      </h6>
                      <h6>
                        Rp
                        {formatNumberWithout(String(item?.total_amount || ""))}
                      </h6>
                    </Col>
                    <hr />
                  </>
                )}
              </Col>
            );
          })}
        </Col>
      </Col>
      <h3 className="text-black fw-bold mb-2">Riwayat Komisi</h3>
      <Col className="py-2 px-1 mb-3" xs={12}>
        <Col className="card-event p-3">
          {dataHistory.length > 0 ? (
            dataHistory.map((item, index) => {
              return (
                <>
                  <Col
                    className="d-flex align-items-center justify-content-between mb-3"
                    key={index}
                  >
                    <h6>{formatDate(item?.created_at)}</h6>
                    <h6>{item?.code || ""}</h6>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-between">
                    <h3>+ {formatNumberWithout(String(item?.amount))}</h3>
                    <h6
                      className=""
                      style={{
                        color: "#35C759",
                        borderRadius: "10px",
                        padding: "10px",
                        border: "1px solid #35C759",
                      }}
                    >
                      <CheckCircle size={19} fill="#35C759" color="white" />{" "}
                      Berhasil
                    </h6>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-start">
                    <h6
                      className=""
                      style={{
                        color: "white",
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "10px",
                        paddingBlock: "2px",
                        marginRight: "10px",
                        paddingInline: "10px",
                        border: "1px solid var(--primary-color)",
                      }}
                    >
                      {" "}
                      Komisi
                    </h6>
                    <h6>{item?.event?.title || ""}</h6>
                  </Col>
                  <hr />
                </>
              );
            })
          ) : (
            <Col className="text-center">
              <h6 className="text-black">Data Kosong!</h6>
            </Col>
          )}
        </Col>
      </Col>
      <Col className="text-center">version 0.0.1</Col>
    </Container>
  );
};

export default Home;
