import { Button, Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { ArrowUpRight, Award, Calendar, MapPin } from "react-feather";
import { useNavigate } from "react-router-dom";
import { formatDate, formatNumberWithout, maskName } from "../../helper";

import TicketIcon from "../../assets/ticket.svg";

import PocketsLevelLogo1 from "../../assets/pockets-level-1.svg";
import PocketsLevelLogo2 from "../../assets/pockets-level-2.svg";
import PocketsLevelLogo3 from "../../assets/pockets-level-3.svg";
import PocketsLevelLogo4 from "../../assets/pockets-level-4.svg";
import PocketsLevelLogo5 from "../../assets/pockets-level-5.svg";
import { useCallback, useEffect, useState } from "react";
import { event_list, leaderboard_list } from "./fetch";

function Landing() {
  const navigate = useNavigate();
  const [dataEvents, setDataEvents] = useState([]);
  const [dataLeaderboard, setDataLeaderboard] = useState([]);

  const affiliateLevel = [
    {
      level: 1,
      icon: PocketsLevelLogo1,
      color: "#089391",
      range: `< Rp${formatNumberWithout("1500000")}`,
    },
    {
      level: 2,
      icon: PocketsLevelLogo2,
      color: "#0055b0",
      range: `Rp${formatNumberWithout("1500000")} - Rp${formatNumberWithout(
        "2500000"
      )}`,
    },
    {
      level: 3,
      icon: PocketsLevelLogo3,
      color: "#a7489f",
      range: `Rp${formatNumberWithout("2500000")} - Rp${formatNumberWithout(
        "3500000"
      )}`,
    },
    {
      level: 4,
      icon: PocketsLevelLogo4,
      color: "#9b3600",
      range: `Rp${formatNumberWithout("3500000")} - Rp${formatNumberWithout(
        "5000000"
      )}`,
    },
    {
      level: 5,
      icon: PocketsLevelLogo5,
      color: "#cb800f",
      range: `> Rp${formatNumberWithout("5000000")}`,
    },
  ];

  const getRemainingDaysInMonth = () => {
    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    return lastDayOfMonth.getDate() - today.getDate();
  };

  const handleClickLogin = () => {
    navigate("/auth/sign-in", { replace: false });
  };

  const handleGetEventList = useCallback(async () => {
    try {
      const res = await event_list();
      if (res.status) {
        setDataEvents(res.data);
      } else {
        setDataEvents([]);
      }
    } catch (error) {
      setDataEvents([]);
    }
  }, []);

  const handleGetLeaderboard = useCallback(async () => {
    try {
      const res = await leaderboard_list();
      if (res.status) {
        setDataLeaderboard(res.data?.top_amount);
      } else {
        setDataLeaderboard([]);
      }
    } catch (error) {
      setDataLeaderboard([]);
    }
  }, []);

  useEffect(() => {
    handleGetEventList();
    handleGetLeaderboard();
  }, [handleGetEventList, handleGetLeaderboard]);

  return (
    <Container fluid className="landing">
      <Col className="text-center mb-4">
        <Image
          src={require("../../assets/hero-landing.png")}
          className="w-50 mb-1"
        />
        <Image src={require("../../assets/hero.png")} className="w-100 mb-4" />
        <Button
          onClick={() => handleClickLogin()}
          className="btn-secondary-fixed mb-3"
        >
          Daftar/Masuk Sekarang!
        </Button>
        <br />
        <Image
          src={require("../../assets/affiliator-benefit.png")}
          className="w-50"
        />
      </Col>
      <Row className="mb-5">
        <Col xs={4}>
          <h5 className="box-benefit-landing">
            Komisi dari setiap penjualan yang kamu referensikan
          </h5>
        </Col>
        <Col xs={4}>
          <h5 className="box-benefit-landing">
            Akses eksklusif ke promosi dan penawaran khusus
          </h5>
        </Col>
        <Col xs={4}>
          <h5 className="box-benefit-landing">
            Dukungan penuh dari kami untuk membantu kamu sukses
          </h5>
        </Col>
      </Row>
      <Col className="text-center mb-3">
        <Image src={require("../../assets/see-event.png")} className="w-75" />
      </Col>
      <Col className="mb-3 d-flex" style={{ overflow: "scroll" }}>
        {dataEvents?.map((item, index) => {
          return (
            <Col className="py-2 px-1" xs={10}>
              <Col className="card-event">
                <Image
                  src={`${process.env.REACT_APP_API_URL_IMAGE}${item?.event_image}`}
                  className="w-100 mb-3"
                  style={{
                    backgroundColor: "black",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
                <Col className="px-3">
                  <h5 className="fw-bold mb-3">{item?.event_name}</h5>
                  <h6>
                    <Calendar
                      size={16}
                      style={{ marginRight: "5px", marginTop: "-4px" }}
                    />
                    {formatDate(item?.event_enddate || "")} •{" "}
                    {(item?.event_endtime || "").replace(":", ".")}
                  </h6>
                  <h6>
                    <MapPin
                      size={16}
                      style={{ marginRight: "5px", marginTop: "-4px" }}
                    />
                    {item?.event_address}
                  </h6>
                  <hr />
                  <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col xs={2}>
                      <Image src={TicketIcon} />
                    </Col>
                    <Col
                      xs={10}
                      style={{ marginLeft: "-10px", marginBottom: "-5px" }}
                    >
                      <h6 className="mb-0">Keuntungan Hingga</h6>
                      <h5 className="text-start fw-bold">
                        Rp{formatNumberWithout(String(item?.max_fee) || "0")}
                        /tix
                      </h5>
                    </Col>
                  </Col>
                  <Button
                    className="btn-primary mb-2"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL_EVENT}${item?.event_slug}/${item?.event_id}`
                      )
                    }
                  >
                    Lihat Detail Event{" "}
                    <ArrowUpRight size={20} style={{ marginTop: "-2px" }} />
                  </Button>
                  <Button
                    className="btn-secondary mb-3"
                    onClick={() => handleClickLogin()}
                  >
                    Ambil Cuannya Sekarang
                    <ArrowUpRight size={20} style={{ marginTop: "-2px" }} />
                  </Button>
                </Col>
              </Col>
            </Col>
          );
        })}
      </Col>
      <Col className="text-center">
        <Image src={require("../../assets/leaderboard.png")} className="w-75" />
      </Col>
      <Col className="py-2 px-1 mb-3" xs={12}>
        <Col className="card-event p-3">
          <h5 className="mb-3">Top Pockets Affiliator</h5>
          <h6
            className="mb-4"
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "4px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Sisa {getRemainingDaysInMonth()} hari lagi, sebelum diperbarui
          </h6>
          {dataLeaderboard?.map((item, index) => {
            return (
              <Col key={index}>
                {index === 0 ? (
                  <Col className="leaderboard-number-one mb-4">
                    <h5 className="text-black mb-3">
                      <Award style={{ marginRight: "5px" }} />
                      Affiliator Terbaik Bulan Ini
                    </h5>
                    <Col className="d-flex align-items-center justify-content-between text-black">
                      <h6>
                        {index + 1}. {maskName(item?.fullname || "")}
                      </h6>
                      <h6>
                        Rp
                        {formatNumberWithout(String(item?.total_amount || ""))}
                      </h6>
                    </Col>
                  </Col>
                ) : (
                  <>
                    <Col className="d-flex align-items-center justify-content-between text-black mb-2">
                      <h6>
                        {index + 1}. {maskName(item?.fullname || "")}
                      </h6>
                      <h6>
                        Rp
                        {formatNumberWithout(String(item?.total_amount || ""))}
                      </h6>
                    </Col>
                    <hr />
                  </>
                )}
              </Col>
            );
          })}
        </Col>
      </Col>
      <Col className="text-center mb-4">
        <Image src={require("../../assets/level-desc.png")} className="w-75" />
      </Col>

      <Col className="d-flex align-items-center" style={{ overflow: "scroll" }}>
        {affiliateLevel?.map((item, index) => {
          return (
            <Col
              xs={11}
              key={index}
              className={`box-affiliate-level-${item?.level} mb-3`}
            >
              <Col className="d-flex align-items-center justify-content-between">
                <h5 className="fw-bold">AFFILIATE LEVEL {item?.level}</h5>
                <Image src={item?.icon} />
              </Col>
              <h6
                style={{
                  backgroundColor: "white",
                  paddingBlock: "5px",
                  paddingInline: "10px",
                  borderRadius: "10px",
                  color: item?.color,
                  width: "fit-content",
                }}
              >
                Range Pendapatan
              </h6>
              <h3 className="mb-3 text-white">{item?.range}</h3>
              <h6>
                Kamu bisa mencapai level ini dengan cara meningkatkan
                penghasilan pockets affiliatemu hingga lebih dari Rp 1.000.000
              </h6>
            </Col>
          );
        })}
      </Col>
    </Container>
  );
}

export default Landing;
