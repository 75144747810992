import { GET, GET_PUBLIC, POST } from "../../api/api";

async function event_list(param) {
  try {
    const res = await GET_PUBLIC("events", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function leaderboard_list(param) {
  try {
    const res = await GET_PUBLIC("leaderboards", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function profile(param) {
  try {
    const res = await GET("profile", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function share(id) {
  try {
    const res = await POST(`share/${id}`);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function history(id) {
  try {
    const res = await GET(`history`);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function withdraw(params) {
  try {
    const res = await POST("withdraw", params);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { event_list, leaderboard_list, profile, share, history, withdraw };
