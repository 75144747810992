import toast from "react-hot-toast";
import { typeMutation } from "./optionValue";

const formatNumberWithout = (number) => {
  try {
    const formattedNumber = number.toLocaleString();
    const [integerPart, decimalPart] = formattedNumber.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const formattedCurrency = decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
    return formattedCurrency;
  } catch (e) {
    console.error(e);
  }
};

const dateformatYMDDH = (tanggalISO) => {
  try {
    if (!tanggalISO || tanggalISO === " ") {
      return "";
    } else {
      const options = {
        weekday: "short", // Menambahkan nama hari
        hour: "2-digit",
        minute: "2-digit",
        day: "numeric",
        month: "short",
        year: "numeric",
      };

      // Konversi ke objek Date
      const tanggal = new Date(tanggalISO);

      // Kurangi 7 jam
      const tanggalMinus7Jam = new Date(tanggal.getTime());

      // Format tanggal sesuai zona waktu dan opsi
      return tanggalMinus7Jam.toLocaleString("id-ID", options);
    }
  } catch (error) {
    return null;
  }
};

function removeNonNumbers(input) {
  if (input === undefined || input === null || input === "") {
    return "";
  } else {
    return input.replace(/[^0-9]/g, "");
  }
}

const removeNumberSymbol = (inputString) => {
  return inputString.replace(/[^a-zA-Z_.]/g, "");
};

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith("0")) {
    return "62" + phoneNumber.slice(1);
  }
  return phoneNumber;
};

const formatPhoneNumberRev = (phoneNumber) => {
  if (phoneNumber.startsWith("62")) {
    return "0" + phoneNumber.slice(2);
  }
  return phoneNumber;
};

const checkPrefix = (text) => {
  // Konversi integer ke string jika bukan string
  const strText = typeof text === "number" ? text.toString() : text;

  if (strText.startsWith("08")) {
    return true;
  } else if (strText.startsWith("62")) {
    return true;
  } else {
    return false;
  }
};

const toCamelCase = (str) => {
  return str
    .toLowerCase() // Ubah semua huruf menjadi kecil
    .replace(/[^a-zA-Z0-9 ]/g, "") // Hapus karakter spesial
    .split(" ") // Pisahkan berdasarkan spasi
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Setiap kata huruf pertama besar
    .join(" ");
};

const changeNameTypeMutation = (value) => {
  const mutation = typeMutation?.find((item) => item.value === value);
  return mutation ? mutation.label : null;
};

const formatVoucherInput = (value) => {
  if (value === undefined) {
    return "";
  }
  // Hapus semua karakter selain angka
  let sanitizedValue = value.replace(/\D/g, "");

  // Batasi panjang maksimal 16 karakter
  if (sanitizedValue.length > 12) {
    sanitizedValue = sanitizedValue.substring(0, 12);
  }

  // Tambahkan tanda "-" setiap 4 karakter
  const formattedValue = sanitizedValue.match(/.{1,3}/g)?.join("-") || "";

  return formattedValue;
};

const adjustToWIB = (date) => {
  const adjustedDate = new Date(date);
  adjustedDate.setHours(adjustedDate.getHours() + 7);
  return adjustedDate;
};

// Format tanggal ke YYYY-MM-DD
const formatDate = (dateString) => {
  if (dateString === "") {
    return "";
  }
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

function formatCurrencyInput(input) {
  const numericInput = input.replace(/[^0-9.]/g, "");
  const [integerPart, decimalPart] = numericInput.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedCurrency = decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
  return formattedCurrency;
}

const removeCurrencyFormat = (inputString) => {
  return inputString.replaceAll(",", "");
};

function maskName(name) {
  try {
    if (!name.trim()) return "";

    return name
      .split(" ")
      .map((word) =>
        word.length > 4
          ? word.slice(0, 4) + "*".repeat(Math.max(0, word.length - 4))
          : word.slice(0, 2) + "*".repeat(Math.max(0, word.length - 2))
      )
      .join(" ");
  } catch (error) {
    return "";
  }
}

const generateShareUrl = (platform, name, code = null) => {
  const APP_URL = process.env.REACT_APP_URL || "https://yourapp.com"; // Sesuaikan dengan env atau default

  const url = {
    facebook: {
      uri: "https://www.facebook.com/sharer/sharer.php?u=",
    },
    twitter: {
      uri: "https://x.com/intent/post?url=",
      text: `Mari bergabung di acara ${name}, Ini akan sangat seru! Klik link berikut:`,
    },
    whatsapp: {
      uri: "https://wa.me/?text=",
      text: `Mari bergabung di acara ${name}, Ini akan sangat seru! Klik link berikut: `,
    },
    telegram: {
      uri: "https://t.me/share/url?url=",
      text: `Mari bergabung di acara ${name}, Ini akan sangat seru! Klik link berikut: `,
    },
  };

  if (!url[platform]) {
    console.error("Platform tidak ditemukan");
    return "";
  }

  let shareUri = url[platform].uri;
  let text = url[platform].text || "";
  let extra = url[platform].extra || [];

  let shareUrl = `${APP_URL}/event/${code}`;
  let finalUrl = shareUri + encodeURIComponent(shareUrl);

  if (platform === "whatsapp" || platform === "telegram") {
    finalUrl = shareUri + encodeURIComponent(text + " " + shareUrl);
  } else {
    if (extra && Object.keys(extra).length > 0) {
      Object.entries(extra).forEach(([key, value]) => {
        finalUrl += `&${key}=${value}`;
      });
    }
    if (text) {
      finalUrl += `&text=${encodeURIComponent(text)}`;
    }
  }

  return finalUrl;
};

const copyToClipboard = async (text) => {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      // ✅ Metode modern (HTTPS diperlukan)
      await navigator.clipboard.writeText(text);
    } else {
      // ✅ Metode fallback untuk iOS & Android (Menggunakan `execCommand`)
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed"; // Menghindari scroll ke bawah
      textArea.style.opacity = "0"; // Menyembunyikan elemen
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      const successful = document.execCommand("copy");
      document.body.removeChild(textArea);

      if (!successful) throw new Error("Copy failed");
    }
    toast.success("Link Berhasil Disalin!");
  } catch (err) {
    // toast.error(err);
    console.error("Error copying text: ", err);
  }
};

export {
  formatNumberWithout,
  removeCurrencyFormat,
  checkPrefix,
  changeNameTypeMutation,
  generateShareUrl,
  copyToClipboard,
  maskName,
  toCamelCase,
  formatCurrencyInput,
  dateformatYMDDH,
  formatPhoneNumberRev,
  formatDate,
  adjustToWIB,
  formatPhoneNumber,
  formatVoucherInput,
  removeNumberSymbol,
  removeNonNumbers,
};
