const bankTujuan = [
  {
    id: 6,
    value: "BRI - Xendit",
    label: "Virtual Account BRI",
  },
  {
    id: 8,
    value: "MANDIRI - Xendit",
    label: "Virtual Account MANDIRI",
  },
  {
    id: 9,
    value: "BNI - Xendit",
    label: "Virtual Account BNI",
  },
];

const bankTujuanWithdraw = [
  {
    id: 6,
    value: "Bank BRI",
    label: "Bank BRI",
  },
  {
    id: 8,
    value: "Bank MANDIRI",
    label: "Bank MANDIRI",
  },
  {
    id: 9,
    value: "Bank BNI",
    label: "Bank BNI",
  },
  {
    id: 9,
    value: "Bank BCA",
    label: "Bank BCA",
  },
  {
    id: 9,
    value: "Seabank",
    label: "Seabank",
  },
  {
    id: 9,
    value: "Bank CIMB Niaga",
    label: "CIMB Niaga",
  },
];

const typeMutation = [
  { value: "ath", label: "ATH" },
  { value: "ath_paid", label: "ATH" },
  { value: "member", label: "Member" },
  { value: "member_paid", label: "Member" },
  { value: "package", label: "Paket Produk" },
  { value: "package_paid", label: "Paket Produk" },
  { value: "package_dp", label: "Paket Produk" },
  { value: "package_installment", label: "Angsuran" },
  { value: "mitra", label: "Member Mitra" },
];

const genderOptions = [
  { value: "male", label: "Laki-Laki" },
  { value: "female", label: "Perempuan" },
];

const optionTypeMutation = [
  {
    id: "1",
    value: "Penambahan Saldo Pending Dari Komisi",
  },
  {
    id: "2",
    value: "Penambahan Saldo Tersedia Dari Saldo Pending",
  },
  {
    id: "3",
    value: "Pengajuan Pencairan Berhasil Dicairkan Ke Rekening Bank",
  },
  {
    id: "4",
    value: "Pengajuan Pencairan Sedang Dalam Proses",
  },
  {
    id: "5",
    value: "Pengajuan Pencairan Gagal Dilakukan Karna Kesalahan",
  },
];

export {
  bankTujuan,
  genderOptions,
  optionTypeMutation,
  typeMutation,
  bankTujuanWithdraw,
};
