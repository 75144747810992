import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/Home/home";
import Login from "./page/Auth/login";
import Landing from "./page/Landing/landing";
import Register from "./page/Auth/register";
import NotFound from "./page/NotFound/notfound";
import secureLocalStorage from "react-secure-storage";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route
          path="/"
          element={
            secureLocalStorage.getItem("statusLogin") ? <Home /> : <Landing />
          }
        />
        {secureLocalStorage.getItem("statusLogin") && (
          <Route path="/home" element={<Home />} />
        )}
        <Route path="/auth/sign-in" element={<Login />} />
        <Route path="/auth/sign-up" element={<Register />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
