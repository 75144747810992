import { POST_PUBLIC } from "../../api/api";

async function login(param) {
  try {
    const res = await POST_PUBLIC("login", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function register(param) {
  try {
    const res = await POST_PUBLIC("account", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function logout() {
  try {
    const res = await POST_PUBLIC("auth/logout/");
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { login, logout, register };
