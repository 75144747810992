import { Button, Col, Form, Image, InputGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { bankTujuanWithdraw } from "../../optionValue";
import { useState } from "react";
import { InputGroupText } from "reactstrap";
import { Eye, EyeOff } from "react-feather";
import { formatPhoneNumberRev, removeNumberSymbol } from "../../helper";
import { register } from "./fetch";

function Register() {
  const navigate = useNavigate();
  const [nameVal, setNameVal] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [phoneVal, setPhoneVal] = useState("");
  const [bankNameVal, setBankNameVal] = useState("");
  const [bankAccountVal, setBankAccountVal] = useState("");
  const [igVal, setIgVal] = useState("");
  const [ttVal, setTtVal] = useState("");

  const [passwordShow, setPasswordShow] = useState(false);

  const handleClickLogin = () => {
    navigate("/auth/sign-in", { replace: true });
  };

  const handleClickRegis = async (e) => {
    e.preventDefault();
    try {
      const params = {
        email: emailVal,
        phone: formatPhoneNumberRev(phoneVal),
        password: passwordVal,
        fullname: nameVal,
        bank_name: bankNameVal?.value,
        bank_number: bankAccountVal,
        ig_username: igVal,
        tiktok_username: ttVal,
      };
      console.log(params);
      const res = await register(params);
      if (res.status) {
        toast.success("Berhasil Login, Tunggu Sebentar");
        navigate("/auth/sign-in", { replace: true });
      } else {
        toast.error(res.message);
        if (res.errors?.email) {
          toast.error(res.errors?.email?.[0]);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container fluid className="register">
      <Col className="text-center mb-5">
        <Image src={require("../../assets/login-hero.png")} className="w-100" />
      </Col>
      <Form className="mb-2" onSubmit={handleClickRegis}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Masukkan email"
          onChange={(e) => setEmailVal(e.target.value)}
          required
          className="mb-3"
        />
        <Form.Label>Nama</Form.Label>
        <Form.Control
          type="name"
          onChange={(e) => setNameVal(e.target.value)}
          placeholder="Masukkan Nama Lengkap"
          required
          className="mb-3"
        />
        <Form.Label>No. Handphone</Form.Label>
        <Form.Control
          type="number"
          onChange={(e) => setPhoneVal(formatPhoneNumberRev(e.target.value))}
          value={formatPhoneNumberRev(phoneVal)}
          placeholder="Masukkan No. Handphone"
          required
          className="mb-3"
        />

        <Form.Label>Kata Sandi</Form.Label>
        <InputGroup>
          <Form.Control
            type={passwordShow ? "text" : "password"}
            required
            onChange={(e) => setPasswordVal(e.target.value)}
            placeholder="Masukkan Kata Sandi"
            className="mb-3"
          />
          <InputGroupText
            className="mb-3"
            onClick={() => setPasswordShow(!passwordShow)}
          >
            {passwordShow ? <Eye /> : <EyeOff />}
          </InputGroupText>
        </InputGroup>

        <Form.Label>Nama Akun Sosial Media (Instagram)</Form.Label>
        <Form.Control
          type="text"
          required
          onChange={(e) => setIgVal(e.target.value)}
          value={`@${removeNumberSymbol(igVal)}`}
          placeholder="Akun Sosial Media (Instagram)"
          className="mb-3"
        />

        <Form.Label>Nama Akun Sosial Media (Tiktok)</Form.Label>
        <Form.Control
          type="text"
          required
          onChange={(e) => setTtVal(e.target.value)}
          value={`@${removeNumberSymbol(ttVal)}`}
          placeholder="Masukkan Kata Sandi (Tiktok)"
          className="mb-3"
        />

        <Form.Label>Bank Tujuan (Untuk Pencairan Saldo)</Form.Label>
        <Select
          options={bankTujuanWithdraw}
          required
          onChange={(option) => setBankNameVal(option)}
          value={bankNameVal}
          isSearchable={false}
          className="mb-3"
        />

        <Form.Label>Nomor Rekening (Untuk Pencairan Saldo)</Form.Label>
        <Form.Control
          type="number"
          required
          onChange={(e) => setBankAccountVal(e.target.value)}
          placeholder="Masukkan Nomor Rekening"
          className="mb-3"
        />

        <Form.Check
          label="Saya Menyetujui S&K Pocketsid"
          required
          className="mb-3"
        />

        <Button className="btn-primary mb-3" type="submit">
          Daftar Sekarang
        </Button>
      </Form>
      <Col className="d-flex align-items-center justify-content-center">
        <h6>Sudah Punya Akun?</h6>
        <h6
          onClick={handleClickLogin}
          style={{
            textDecoration: "underline",
            color: "var(--primary-color)",
            marginLeft: "5px",
          }}
        >
          Masuk Sekarang
        </h6>
      </Col>
    </Container>
  );
}

export default Register;
