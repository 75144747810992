import { GET_PUBLIC } from "../../api/api";

async function event_list(param) {
  try {
    const res = await GET_PUBLIC("events", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function leaderboard_list(param) {
  try {
    const res = await GET_PUBLIC("leaderboards", param);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { event_list, leaderboard_list };
