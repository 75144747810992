import { useState } from "react";
import { Button, Col, Form, Image, InputGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Eye, EyeOff } from "react-feather";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { InputGroupText } from "reactstrap";
import { login } from "./fetch";

function Login() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleClickRegister = () => {
    navigate("/auth/sign-up", { replace: false });
  };

  const handleClickLogin = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    try {
      if (emailVal === "" || passwordVal === "") {
        setDisableButton(false);
        return toast.error("Periksa Kembali Data Kamu");
      }
      const params = { email: emailVal, password: passwordVal };
      const res = await login(params);
      if (res.status) {
        secureLocalStorage.setItem("statusLogin", true);
        secureLocalStorage.setItem("token", res.data.token);
        const toastId = toast.loading("Berhasil Login, Tunggu Sebentar");
        navigate("/", { replace: true });
        setTimeout(() => {
          toast.dismiss(toastId);
          setDisableButton(false);
          window.location.reload();
        }, 3000);
      } else {
        toast.error(res.message);
        setDisableButton(false);
      }
    } catch (error) {
      toast.error("Terjadi Kesalahan");
      setDisableButton(false);
    }
  };

  return (
    <Container fluid className="login px-4">
      <Col className="text-center mb-5">
        <Image src={require("../../assets/login-hero.png")} className="w-100" />
      </Col>
      <Form onSubmit={handleClickLogin}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          required
          placeholder="Masukkan email"
          className="mb-3"
          value={emailVal}
          onChange={(e) => setEmailVal(e.target.value)}
        />
        <Form.Label>Kata Sandi</Form.Label>
        <InputGroup>
          <Form.Control
            type={!showPassword ? "password" : "text"}
            required
            placeholder="Masukkan Kata Sandi"
            className="mb-3"
            value={passwordVal}
            onChange={(e) => setPasswordVal(e.target.value)}
          />
          <InputGroupText
            className="mb-3"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <Eye /> : <EyeOff />}
          </InputGroupText>
        </InputGroup>

        <u>
          <h6
            style={{ width: "fit-content" }}
            onClick={() =>
              window.open(
                "https://pockets.id/customer/forget-password",
                "_blank"
              )
            }
            className="fw-lighter mb-3"
          >
            Lupa Kata Sandi?
          </h6>
        </u>
        <Button
          className="btn-primary mb-3"
          type="submit"
          disabled={disableButton}
        >
          Masuk
        </Button>
      </Form>
      <Col className="d-flex align-items-center justify-content-center">
        <h6>Belum Punya Akun?</h6>
        <h6
          onClick={handleClickRegister}
          style={{
            textDecoration: "underline",
            color: "var(--primary-color)",
            marginLeft: "5px",
          }}
        >
          Daftar Sekarang
        </h6>
      </Col>
    </Container>
  );
}

export default Login;
